var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-10 col-12 mx-0", attrs: { id: "about-us-container" } },
    [
      _c(
        "v-col",
        {
          staticClass: "col-0 col-md-5 mt-0 col-12",
          attrs: { id: "about-us-illustration-col" },
        },
        [
          _c(
            "div",
            [
              _c("v-img", {
                staticClass: "mt-12",
                attrs: {
                  id: "about-us-illustration",
                  "max-width": "310px",
                  contain: "",
                  height: _vm.$vuetify.breakpoint.smAndDown ? 350 : _vm.auto,
                  "lazy-src": require("../assets/images/mobile-mechanics-coming-to-your-home-illustration@2x.png"),
                  src: require("../assets/images/mobile-mechanics-coming-to-your-home-illustration@2x.png"),
                },
              }),
              _c(
                "p",
                {
                  staticClass: "hidden-xs-only",
                  attrs: { id: "about-us-illustration-text" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("AboutUsPage.IllustrationText")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c("v-col", { staticClass: "col-md-7 mt-md-16 mt-5" }, [
        _c("article", { staticStyle: { "max-width": "765px" } }, [
          _c("span", { staticClass: "information" }, [
            _vm._v(" " + _vm._s(_vm.$t("AboutUsPage.Title")) + " "),
          ]),
          _c("h1", [
            _vm._v(" " + _vm._s(_vm.$t("AboutUsPage.SubTitle")) + " "),
          ]),
          _c("section", [
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("AboutUsPage.P1")) + " ")]),
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("AboutUsPage.P2")) + " ")]),
            _c("p", [_vm._v(" " + _vm._s(_vm.$t("AboutUsPage.P3")) + " ")]),
          ]),
          _c("section", { attrs: { id: "contacts" } }, [
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("AboutUsPage.ForPartnerShipContact")) + " "
              ),
              _c("a", { attrs: { href: "mailto:tonyb@ucarmobile.com" } }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(" tonyb@ucarmobile.com "),
                ]),
              ]),
            ]),
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("AboutUsPage.ForMediaInquiries")) + " "
              ),
              _c("a", { attrs: { href: "mailto:tonyb@ucarmobile.com" } }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(" tonyb@ucarmobile.com "),
                ]),
              ]),
            ]),
            _c("p", [
              _vm._v(
                " " + _vm._s(_vm.$t("AboutUsPage.MechanicLookingForWork")) + " "
              ),
              _c("a", { attrs: { href: "mailto:tonyb@ucarmobile.com" } }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(" tonyb@ucarmobile.com "),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }